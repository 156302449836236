const preloader = document.querySelector('.preloader');

const hidePreloader = () => {
	preloader.classList.add('preloader--invisible');
	
	setTimeout(() => {
		preloader.classList.add('preloader--hidden');
	}, 200);
};

export { hidePreloader };