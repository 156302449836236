const amountNode = document.querySelector('.amount');
const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

let amount = localStorage.amount - getRandomInt(1, 4) || 23829;

const addLinesToNum = num => {
    const result = String(num)
	    .split('')
	    .map(digit => `<span class="amount__box">${digit}</span>`)
	    .join('');

	return result;
};

const setAmount = () => {
	// Вычитаем рандомное число //
	amount = amount - getRandomInt(1, 2);

	localStorage.setItem('amount', amount);
	amountNode.innerHTML = addLinesToNum(amount);
};

// Рандомный интервал //
const count = () => {
	const interval = getRandomInt(20, 30) * 1000;

	setTimeout(() => {
		setAmount();
		count();
	}, interval);
};

amountNode.innerHTML = addLinesToNum(amount);

setTimeout(() => {
	setAmount();
	count();
}, 5000);
