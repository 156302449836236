export default class Arrow {
	constructor(node) {
		this.node = node;
		this._classNames = {
			active: 'arrow--visible',
			inactive: 'arrow--invisible',
			inversed: 'arrow--inverse'
		};

		this._delay = 10000;
		this._timer;

		this.hide = this.hide.bind(this);
		this.show = this.show.bind(this);
		this.handleTimer = this.handleTimer.bind(this);

		// Init //

		this.handleTimer();
	}

	show() {
		this.node.show();

		this.node.addClass(this._classNames.active);
		this.node.removeClass(this._classNames.inactive);
	}

	hide() {
		this.node.removeClass(this._classNames.active);
		this.node.addClass(this._classNames.inactive);

		this.node.on('transitionend', event => {
			if (event.originalEvent.propertyName === 'opacity' && Number(this.node.css('opacity')) === 0) {
				this.node.hide();
			};
		});
	}

	inverse() {
		this.node.addClass(this._classNames.inversed);
	}

	cancelInverse() {
		this.node.removeClass(this._classNames.inversed);
	}

	cancelTimer() {
		if (this._timer) {
			// Если в данный момент таймер работает, то сбрасываем его и начинаем отсчёт заново
			clearTimeout(this._timer);

			this._timer = null;
		};
	}

	handleTimer() {
		this.cancelTimer();

		this._timer = setTimeout(this.show, this._delay);
	}
}