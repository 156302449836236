import $ from 'jquery';
import { store } from '../store/reducers';
import { showModal, hideModal, preorderModal, offerModal, letterModal } from '../modal/modal';
import { priceSlider, removeSlide } from '../sliders/sliders';

const buyButton = $('.button--buy');
const closeModal = $('.modal__close');
const scrollerButton = $('.button--scroller');
const submitSpecialButton =$('.form--preorder .form__submit')
const permissionButton = $('.modal__button--permission');
const preorderButton = $('.modal__button--preorder');
const offerButton = $('.modal__button--offer');
const letterbutton = $('.button--letter');

const handleButtonVisibility = state => {
	if (state === 'HIDDEN') {
		buyButton.removeClass('button--faded-in');
		buyButton.addClass('button--hidden');
	} else {
		buyButton.removeClass('button--hidden');
		buyButton.width();
		buyButton.addClass('button--faded-in');
	};
};

const valueSubscriber = store.subscribe(() => {
	handleButtonVisibility(store.getState().button);
});

const closeClickHandler = event => {
	const targetModalSelector = $(event.currentTarget).data('target');
	const targetModal = document.querySelector(targetModalSelector);

	hideModal(targetModal);
	store.dispatch({ type: 'ZEROIZE_PRODUCT', id: 7 });
};

handleButtonVisibility(store.getState().button);

closeModal.on('click', closeClickHandler)

permissionButton.on('click', event => {
	if (!event.currentTarget.dataset.allowed) {
		// Если посетителю нет 18, то удаляем алкоголь, оставляем только графины //

		removeSlide(priceSlider, 1);
		removeSlide(priceSlider, 1);
		removeSlide(priceSlider, 2);
		removeSlide(priceSlider, 2);

		store.dispatch({ type: 'REMOVE_PRODUCT', id: 2 });
		store.dispatch({ type: 'REMOVE_PRODUCT', id: 3 });
		store.dispatch({ type: 'REMOVE_PRODUCT', id: 5 });
		store.dispatch({ type: 'REMOVE_PRODUCT', id: 6 });

		// Ставим первый товар активным //
		store.dispatch({ type: 'INCREASE_PRODUCT', id: 1 });
	};

	closeClickHandler(event);
});

preorderButton.on('click', event => {
	closeClickHandler(event);
	showModal(preorderModal);
});

offerButton.on('click', event => {
	closeClickHandler(event);
	showModal(offerModal);
});

letterbutton.on('click', event => {
	// closeClickHandler(event);
	showModal(letterModal);
});

export { buyButton, scrollerButton };