import { createStore, combineReducers } from 'redux';

const button = (state = 'VISIBLE', { type, visibility }) => {
	switch(type) {
		case 'SET_VISIBILITY':
			return visibility;
		default:
			return state;
	};
};

const particles = (state = [], { type, text, title, id }) => {
	switch(type) {
		case 'ADD_PARTICLE_DATA':
			return [
				...state,
				{ id, title, text }
			];
		default:
			return state;
	};
};

const item = (state, { type, title, id, price, amount }) => {
	switch(type) {
		case 'ADD_PRODUCT':
			return {
				title,
				id,
				price,
				amount: 0
			};
		case 'SET_PRODUCT_AMOUNT':
			if (state.id !== id) return state;

			return {
				...state,
				amount
			};
		case 'INCREASE_PRODUCT':
			if (state.id !== id) return state;

			return {
				...state,
				amount: state.amount + 1
			};
		case 'DECREASE_PRODUCT':
			if (state.id !== id) return state;

			return {
				...state,
				amount: state.amount - 1
			};
		case 'ZEROIZE_PRODUCT':
			if (state.id !== id) return state;

			return {
				...state,
				amount: 0
			};
		case 'REMOVE_PRODUCT':
			if (state.id !== id) return true;

			return false;
		default:
			return state;
	};
};

const products = (state = [], action) => {
	switch(action.type) {
		case 'ADD_PRODUCT':
			return [
				...state,
				item(null, action)
			];
		case 'INCREASE_PRODUCT':
			return state.map(product => item(product, action));
		case 'SET_PRODUCT_AMOUNT':
			return state.map(product => item(product, action));
		case 'DECREASE_PRODUCT':
			return state.map(product => item(product, action));
		case 'ZEROIZE_PRODUCT':
			return state.map(product => item(product, action));
		case 'REMOVE_PRODUCT':
			return state.filter(product => item(product, action));
		default:
			return state;
	};
};

const offer = (state = 0, action) => {
	switch(action.type) {
		case 'SET_OFFER_ID':
			return action.id;
		default:
			return state;
	};
};


const appReducer = combineReducers({
	products,
	button,
	offer,
	particles
});

const store = createStore(appReducer);

export { store };