import SimpleBar from 'simplebar';
import { store } from '../../_modules/store/reducers';

const main = document.querySelector('.main');
const ageModal = document.querySelector('.modal--age');
const thanksModal = document.querySelector('.modal--thanks');
const specialModal = document.querySelector('.modal--special');
const preorderModal = document.querySelector('.modal--preorder');
const offerModal = document.querySelector('.modal--offer');
const letterModal = document.querySelector('.modal--contact');
const sentLetterModal = document.querySelector('.modal--sent');
let modalTimerID;

const updateOfferID = () => {
	const offerIDNode = document.querySelector('.modal__offer-id');

	offerIDNode.textContent = store.getState().offer;
};

const showModal = node => {
	main.classList.add('main--blurred');
	node.classList.add('modal--visible');
};

const hideModal = node => {
	main.classList.remove('main--blurred');
	node.classList.remove('modal--visible');
};

showModal(ageModal);

// Показываем окно,  если пользователь пытается уйти //

const mouseoutHandler = event => {
	if (event.clientY < 0) {
		modalTimerID = setTimeout(() => {
			hideModal(ageModal);
			hideModal(thanksModal);
			hideModal(preorderModal);
			hideModal(offerModal);
			hideModal(letterModal);
			showModal(specialModal);
		}, 300);

		// Если окно было показано, то больше мы его показывать не будем
		
		event.currentTarget.removeEventListener('mouseout', mouseoutHandler);
	};

};

document.addEventListener('mouseout', mouseoutHandler);

// Очищаем таймер, если пользователь не пытался уйти, а просто случайно провел мышью вне окна //

document.addEventListener('mouseover', event => clearTimeout(modalTimerID));

new SimpleBar(preorderModal.querySelector('.modal__block-overflow'), { autoHide: false, wrapContent: true });
new SimpleBar(offerModal.querySelector('.modal__block-overflow'), { autoHide: false, wrapContent: true });

store.subscribe(updateOfferID);

export { showModal, hideModal, ageModal, thanksModal, specialModal, preorderModal, offerModal, letterModal, sentLetterModal };