import $ from 'jquery';
import { store } from '../store/reducers';

const productNodes = $('.card');

const changeProductAmount = (event, id) => {
	const target = $(event.target);
	const currentTarget = $(event.currentTarget);

	if (target.hasClass('counter__control--plus') || currentTarget.hasClass('card__image-composition')) {
		store.dispatch({ type: 'INCREASE_PRODUCT', id });
	} else if (target.hasClass('counter__control--minus') && getCurrentProduct(id).amount > 0) {
		store.dispatch({ type: 'DECREASE_PRODUCT', id });
	};
};

const setButtonVisibility = () => {
	if (isAnyProductSelected()) {
		store.dispatch({ type: 'SET_VISIBILITY', visibility: 'VISIBLE' });
	} else {
		store.dispatch({ type: 'SET_VISIBILITY', visibility: 'HIDDEN' });
	};
};

const isAnyProductSelected = () => {
	const amount = store.getState().products.reduce((result, product) => {
		return result = result + product.amount;
	}, 0);

	return amount > 0;
};

const getCurrentProduct = id => {
	return store.getState().products.filter(product => product.id === id)[0];
};

const handleAppearance = (state, node) => {
	if (state.amount === 0) {
		node.addClass('card--unactive');
		node.removeClass('card--active');
	} else {
		node.addClass('card--active');
		node.removeClass('card--unactive');
	};
};

const changeValue = (node, value) => {
	node.html(value);
};

const createCounter = value => {
	// Создаёт элемент счётчика в корзине //

	const counter = $('<div/>').addClass('counter counter--small counter--purple');
	const coontrolMinus = $('<button/>').addClass('counter__control counter__control--minus').text('–');
	const coontrolPlus = $('<button/>').addClass('counter__control counter__control--plus').text('+');
	const valueField = $('<p/>').addClass('counter__value').text(value);

	return counter.append([coontrolMinus, valueField, coontrolPlus]);
};

const handleCounterClick = event => {
	const card = $(event.currentTarget).closest('.card');
	const id = Number(card.data('id'));

	event.preventDefault();
	changeProductAmount(event, id);
	setButtonVisibility();
};

productNodes.each((index, node) => {
	const counter = $(node).find('.counter');
	const valueField = counter.find('.counter__value');
	let title;
	const id = Number(node.dataset.id);
	const price = Number(node.dataset.price);

	const valueSubscriber = store.subscribe(() => {
		const currentProduct = getCurrentProduct(id);

		if (currentProduct) {
			changeValue(valueField, currentProduct.amount);
			handleAppearance(currentProduct, $(node));
		};
	});

	switch(id) {
		case 1:
			title = "Nanografin RADUGA"
			break;
		case 2:
			title = "Nanografin RADUGA с напитком"
			break;
		case 3:
			title = "Nanografin RADUGA с напитком в подарочной упаковке"
			break;
		case 4:
			title = "Nanografin RADUGA"
			break;
		case 5:
			title = "Nanografin RADUGA с напитком"
			break;
		case 6:
			title = "Nanografin RADUGA с напитком в подарочной упаковке"
			break;
		default:
			title = "Nanografin RADUGA"
			break;
	};

	store.dispatch({ type: 'ADD_PRODUCT', title, id, price });
});


// Привязываем через слайдер, т.к. свойство mobileFirst у слайдера ломает обычную привязку //
$('.slider--pricelist .slider__inner').on('click', '.counter', handleCounterClick);
$('.slider--pricelist .slider__inner').on('click', '.card__image-composition', handleCounterClick);

store.dispatch({ type: 'INCREASE_PRODUCT', id: 2 });
store.dispatch({ type: 'ADD_PRODUCT', title: 'НОВЫЙ', id: 7, price: 7000 });

export { changeProductAmount, setButtonVisibility, createCounter };