import $ from 'jquery';
import SimpleBar from 'simplebar';
import { store } from '../store/reducers';
import { changeProductAmount, setButtonVisibility, createCounter } from '../card/card';

const getSelectedProducts = state => {
	return state.filter(product => product.amount > 0);
};

const removeItem = (event, id) => {
	event.preventDefault();
	store.dispatch({ type: 'ZEROIZE_PRODUCT', id });

	setButtonVisibility();
};

const createArticle = (product, parent) => {
	// Создаёт элемент товара в корзине //

	const wrap = $('<article/>').addClass('card card--thumb')
	const box = $('<div/>').addClass('card__box');
	const closeButton = $('<button/>').addClass('card__close button button--close');
	const title = $('<h4/>').addClass('card__title').text(product.title);
	const footer = $('<footer/>').addClass('card__footer card__footer--row');
	const smallPrice = $('<p/>').addClass('card__price card__price--small').text(`${product.amount} x ${product.price}`);
	const price = $('<p/>').addClass('card__price').text(`${product.price * product.amount} руб.`);
	const priceContainer = $('<div/>');
	const counter = createCounter(product.amount);

	priceContainer.append([smallPrice, price]);
	footer.append([counter, priceContainer]);
	box.append([title, footer]);
	wrap.append([box, closeButton]);
	parent.append(wrap);

	counter.on('click', event => {
		event.preventDefault();
		changeProductAmount(event, product.id);
		setButtonVisibility();
	});

	closeButton.on('click', event => removeItem(event, product.id));
};

const renderProducts = products => {
	// Очищает корзину и заново рендерит выбранные элементы //

	const containers = $('.cart__inner');
	containers.html('');
	products.forEach(product => {
		containers.each((index, container) => createArticle(product, $(container)));
	});
};

const showSum = products => {
	const sum = products.reduce((result, product) => {
		return result = result + (product.amount * product.price);
	}, 0);

	const formSum = $('.form__sum-value');
	const cartSum = $('.cart__sum-value');

	formSum.text(`${sum} руб.`);
	cartSum.text(`${sum} руб.`);
};

const simplebar = new SimpleBar(document.querySelector('.form__cart'), { autoHide: false, wrapContent: true });

store.subscribe(() => {
	renderProducts(getSelectedProducts(store.getState().products));
	simplebar.recalculate();
	showSum(getSelectedProducts(store.getState().products));
});

renderProducts(getSelectedProducts(store.getState().products));
showSum(getSelectedProducts(store.getState().products));