import $ from 'jquery';
import { store } from '../../_modules/store/reducers';
import { isMobile } from '../../_scripts/helpers';

const wrapper = $('.explode');
const explodeInner = $('.explode__inner');
const particles = $('.explode__particle');
const textWrapper = $('.about');
const textContent = $('.about__content');
const titleNode = $('.about__title');
const textNode = $('.about__text');

let groupID;
let prevGroupID;
let timerID;

const focusOnGroup = (id = 0) => {
	groupID = id;

	blurOnParticle();

	if (groupID !== prevGroupID) {
		textWrapper.removeClass('about--visible');
		textWrapper.addClass('about--invisible');
		prevGroupID = groupID;
	};

	if (Number(groupID) === 0) {
		$('.explode__particle').css('opacity', 1);
	} else {
		$('.explode__particle').not(`[data-group="${groupID}"]`).css('opacity', 0.2);
	};

	if (isMobile()) {
		explodeInner.css({ 'transform': `translateX(-${groupID * 15}%)` });
	};
};

const setParticleText = data => {
	titleNode.text(data.title);
	textNode.html(data.text);
};

const blurOnParticle = () => {
	$('.explode__particle').css('opacity', 1);
	explodeInner.css({ 'transform': `translateX(0)` });
};

const handleWrapperOut = event => {
	textWrapper.addClass('about--hidden-xs');
	textContent.addClass('about__content--hidden-xs');
};

const startToggleGroups = () => {
	timerID = setTimeout(() => {
		if (groupID === undefined) {
			groupID = 1;
		} else if (Number(groupID) === 4) {
			groupID = 0;
		} else {
			groupID++;
		};

		focusOnGroup(groupID);
		startToggleGroups();
	}, 5000);
};

const stopToggleGroups = () => {
	clearTimeout(timerID);
};

store.dispatch({ 
	type: 'ADD_PARTICLE_DATA', 
	id: 0, 
	title: 'Конструкция нанографина', 
	text: '<p class="text text--white text--left">Нанографин «RADUGA» – это первая модель из линейки нанографинов «MAGIC JAR» с электронным мультифильтром «FRESHTRONIC» для быстрой фильтрации традиционного русского напитка перед употреблением. Обработка и очистка напитка происходит непосредственно в процессе налива. В результате вы в каждой рюмке получаете безупречно чистый продукт с изумительно мягким вкусом и ароматом, лишенным посторонних примесей.</p>'
});
store.dispatch({ 
	type: 'ADD_PARTICLE_DATA', 
	id: 4, 
	title: 'Электронный блок', 
	text: `
		<p class="text text--white text--left">Мозг нанографина RADUGA. Здесь находятся все электронные элементы, которые превращают обычный графин в волшебный графин «MAGIC JAR». Главные детали – это микропроцессор, блок световых эффектов со светодиодами и фотоэлементом, и блок звуковых эффектов с динамиком.</p>
		<p class="text text--white text--left text--half-visible">Состоит из: Корпус, Воздушный компрессор, Электромотор воздушного компрессора, Гравитационный выключатель положения, Блок световых эффектов с тремя светодиодами, Блок звуковых эффектов с динамиком, Фотоэлемент включает рекламный режим подсветки, Микропроцессор, Основной источник питания: три алкалиновые батареи, Резервный источник питания: две алкалиновые батареи</p>
	`
});
store.dispatch({
	type: 'ADD_PARTICLE_DATA',
	id: 2,
	title: 'Мультифильтр "FRESHTRONIC"',
	text: `
		<p class="text text--white text--left">Сердце нанографина "RADUGA". Именно здесь расположен картридж, заполненный уникальным <abbr title="Вещество, которое притягивает токсины своей поверхностью и поглощает их.">наносорбентом</abbr> из <abbr title="Его скорость адсорбции (поглощения) в 100-1000 раз выше чем у других веществ, а количество поглощаемых им токсинов в 5- 10 раз больше, чем у традиционного активированного угля.">активированного углеродного волокна</abbr>.</p>
		<p class="text text--white text--left">Таким образом мультифильтр "FRESHTRONIC" очищает напиток за 3-5 секунд, пока вы наполняете рюмку, а результат равносилен обработке напитка несколькими килограммами активированного угля высшего качества.</p>
		<p class="text text--white text--left text--half-visible">Состоит из: Картридж мультифильтра "FRESHTRONIC», Слой объемного фильтрующего материала, Три слоя сорбента из активированных углеродных волокон, Пяти микронная мембрана фильтра тонкой очистки</p>
	`
});
store.dispatch({
	type: 'ADD_PARTICLE_DATA',
	id: 1,
	title: 'Горловина',
	text: `
		<p class="text text--white text--left">Горловина выполнена из высококачественных пищевых материалов и оформлена элементами национального рисунка. Барный дозатор обеспечивает удобство и безопасность розлива напитка.</p>
		<p class="text text--white text--left text--half-visible">Состоит из: Горловина, Пробка, Дозатор</p>
	`
});
store.dispatch({
	type: 'ADD_PARTICLE_DATA',
	id: 3,
	title: 'Фиксатор первого включения',
	text: `
		<p class="text text--white text--left">Защитный механический фиксатор разрушается при первом включении электронного фильтра, что гарантирует подлинность нанографина.</p>
		<p class="text text--white text--left">Нанографин "RADUGA" включается с помощью поворотного механизма. Поверните кольцо в сторону зеленого индикатора "ON"– так вы активируете нанографин. Для выключения просто поверните кольцо в сторону красного индикатора "OFF".</p>
		<p class="text text--white text--left text--half-visible">Состоит из: Корпус, Поворотный кольцевой выключатель, Цветовой индикатор выключателя: зеленый ON и красный OFF, Механический предохранитель первого включения</p>
	`
});

particles.hover(event => {
	const target = $(event.currentTarget);

	stopToggleGroups();
	focusOnGroup(target.data('group'));
	event.stopPropagation();
}, null);

wrapper.hover(handleWrapperOut, null);

textWrapper.on('transitionend', event => {
	if (event.originalEvent.propertyName === 'opacity' && $(event.target).css('opacity') === '0') {
		const targetGroup = store.getState().particles.find(group => group.id === groupID);

		setParticleText(targetGroup);
		textContent.removeClass('about__content--hidden-xs');
		textWrapper.removeClass('about--invisible');
		textWrapper.addClass('about--visible');
	}
});

if (isMobile()) {
	particles.on('click', event => {
		const target = $(event.currentTarget);

		handleWrapperOut(event);
		stopToggleGroups();
		focusOnGroup(target.data('group'));
		event.stopPropagation();
	});

	textWrapper.on('click', event => {
		blurOnParticle(event);
		handleWrapperOut(event);
	});
};

export { setParticleText, startToggleGroups, blurOnParticle };