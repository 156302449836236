import $ from 'jquery';
import { isMobile } from '../../_scripts/helpers';

const main = $('.main');
const navbar = $('.navbar');
const navItems = $('.navbar__item');
const closeNavButton = navbar.find('.navbar__close');
const openNavButton = $('.button--nav');

const showNavbar = event => {
	main.addClass('main--blurred');
	navbar.addClass('navbar--visible');
};

const closeNavbar = event => {
	main.removeClass('main--blurred');
	navbar.removeClass('navbar--visible');
};

if (!isMobile()) {

    navItems.find('.path-clip').attr('transform', 'translate(200, 0)');
    navItems.hover(event => {
    	const target = $(event.currentTarget);
    	// Если следующий или предыдущий элемент скрыт, то проходим мимо него //
    	const next = target.next().is(':visible') ? target.next() : target.next().next();
    	const prev = target.prev().is(':visible') ? target.prev() : target.prev().prev();


    	target.find('.path-clip').attr('transform', 'translate(0)');
    	next.addClass('active-sibling');
    	prev.addClass('active-sibling');
    }, event => {
    	const target = $(event.currentTarget);
    	const siblings = $('.active-sibling');

    	siblings.removeClass('active-sibling');
    	target.find('.path-clip').attr('transform', 'translate(200, 0)');
    });

} else {

	navItems.on('click', event => {
		$.fn.fullpage.moveTo(Number(event.currentTarget.dataset.anchor));
		closeNavbar();
	});
	
};

openNavButton.on('click', showNavbar);
closeNavButton.on('click', closeNavbar);

export { showNavbar, closeNavbar };