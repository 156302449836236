import $ from 'jquery';
import 'whatwg-fetch';
import { store } from '../../_modules/store/reducers';
import { showModal, hideModal, thanksModal, preorderModal, letterModal, sentLetterModal } from '../../_modules/modal/modal';

const orderForm = $('.form--cart');
const preorderForm = $('.form--preorder');
const contactForm = $('.form--contact');
const amountInput = $('.form__input--amount');
const ROOT_PATH = 'http://localhost:89/';
// const ROOT_PATH = '/api/';

const createFormBody = (arr, settings) => {
	return arr.reduce((current, item) => {
		return [
			...current,
			`${encodeURIComponent(item[settings.keyName])}=${encodeURIComponent(item[settings.valueName])}`
		];
	}, []).join('&');
};

const getOptions = body => {
	const headers = new Headers({
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
	});

	return {
		method: 'POST',
		headers,
		body
	};
};

const submitForm = (event, { productsFilter }) => {
	const form = $(event.target);
	const fields = form.find('.form__input');
	const loader = $(form.data('loader'));
	const userData = createFormBody([...fields], { keyName: 'name', valueName: 'value' });

	let productsData = store.getState().products
		.filter(productsFilter)
		.reduce((current, item) => { return { ...current, [item.id]: item.amount } }, {});

	if (Object.keys(productsData).length === 0) {
		console.log('Нет товаров в корзине');
		return false;
	};

	fetch(`${ROOT_PATH}user/add`, getOptions(userData))
		.then(data => data.json())
		.then(user => {
			const offerFields = [
				{
					key: 'user_id', 
					value: user.id
				},
				{ 
					key: 'product_ids',
					value: JSON.stringify(productsData)
				}
			];

			const offersData = createFormBody(offerFields, { keyName: 'key', valueName: 'value' });
				
			fetch(`${ROOT_PATH}order/add`, getOptions(offersData))
				.then(data => data.json())
				.then(({ status, id }) => {
					store.dispatch({ type: 'SET_OFFER_ID', id });
					loader.removeClass('loader--visible');
					hideModal(preorderModal);
					showModal(thanksModal);
				})
				.catch(err => console.log(err));
		})
		.catch(err => console.log(err));
}

preorderForm.on('submit', event => {
	event.preventDefault();

	const loader = $(event.target.dataset.loader);

	loader.addClass('loader--visible');
	submitForm(event, { productsFilter: product => product.amount > 0 && product.id === 7 });
});

orderForm.on('submit', event => {
	event.preventDefault();

	const loader = $(event.target.dataset.loader);

	submitForm(event, { productsFilter: product => product.amount > 0 });
});

contactForm.on('submit', event => {
	event.preventDefault();
	
	const form = $(event.target);
	const loader = $(form.data('loader'));
	const fields = form.find('.form__input');
	const userData = createFormBody([...fields], { keyName: 'name', valueName: 'value' });

	loader.addClass('loader--visible');

	fetch(`${ROOT_PATH}user/contact`, getOptions(userData))
		.then(data => data.json())
		.then(({ status }) => {
			loader.removeClass('loader--visible');

			hideModal(letterModal);
			showModal(sentLetterModal);
		})
		.catch(err => {
			loader.removeClass('loader--visible');
			console.log(err);
		});
});

amountInput.on('input', event => {
	const amount = Number(event.target.value);

	if (!isNaN(amount)) {
		store.dispatch({ type: 'SET_PRODUCT_AMOUNT', id: 7, amount: Number(event.target.value) });
	} else {
		store.dispatch({ type: 'ZEROIZE_PRODUCT', id: 7 });
	}
})