// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

window.jQuery = window.$ = require('jquery');
window.IScroll           = require('iscroll');
window.scrolloverflow    = require('fullpage.js/vendors/scrolloverflow');

import 'fullpage.js';
import 'slick-carousel';

import { isMobile } from './helpers';
import { store } from '../_modules/store/reducers';
import { buyButton, scrollerButton } from '../_modules/button/button';
import { showModal, hideModal, ageModal, specialModal } from '../_modules/modal/modal';
import { setParticleText, startToggleGroups, blurOnParticle } from '../_modules/explode/explode';
import { closeNavbar } from '../_modules/pagination/pagination';
import { hidePreloader } from '../_modules/preloader/preloader';
import Arrow from '../_modules/arrow/arrow';

import '../_modules/card/card';
import '../_modules/cart/cart';
import '../_modules/form/form';
import '../_modules/amount/amount';
import '../_modules/pagination/pagination';

$(() => {
	const getSectionByIndex = index => $('.section').eq(index - 1);

	const isVisible = section => $(section).is(':visible');

	const getNearestVisibleSectionIndex = (index, direction) => {
	    const step = direction === 'up' ? -1 : 1,
	        sectionsCount = $('.section').length;

	    while (index >= 0 && index < sectionsCount && !isVisible(getSectionByIndex(index))) {
	        index += step;
	    }

	    return index === sectionsCount ? sectionsCount : index;
	};

	const getAnchors = selector => {
		const anchors = selector.find('.navbar__item');
		const visibleAnchors = anchors.filter(':visible');
		const ids = [...visibleAnchors].map(anchor => anchor.dataset.helper);

		visibleAnchors.each((index, node) => {
			node.dataset.menuanchor = ids[index];
		});

		return ids;
	}

	const setActiveNav = id => {
		const currentNav = $(`.navbar__item[data-anchor=${id}]`);

		$(`.navbar .active`).removeClass('active');

	    currentNav.addClass('active');

	    return $(`.navbar__item.active`);
	};

	// Arrow //

	const scrollArrow = new Arrow($('.arrow'));

	scrollArrow.node.on('click', () => {
		$.fn.fullpage.moveSectionDown();
	});

	let explodeTimer;
    let explodeTimerStepTwo;

	$('#fullpage').fullpage({
		verticalCentered: false,
		scrollOverflow: true,
		normalScrollElements: '.cart__inner, .slider__text, .simplebar-scroll-content, .modal__block-overflow',
		sectionSelector: '.section',
		afterLoad: function(anchorLink, index) {
			setActiveNav(index);
		},
		onLeave: function(index, nextIndex, direction) {
			if (!isMobile()) {
				// Отменяем все, что делалось со стрелкой //
				scrollArrow.hide();
				scrollArrow.cancelTimer();
				scrollArrow.cancelInverse();
			};

		    const nextSection = getSectionByIndex(nextIndex);

		    if(!isVisible(nextSection)) {
		        const sectionIndex = getNearestVisibleSectionIndex(nextIndex, direction);
		        
		        if (sectionIndex !== -1) {
		            $.fn.fullpage.moveTo(sectionIndex);
		        }

		        return false;
		    }

	    	if (nextIndex === 1) {
	    		$('.navbar').addClass('navbar--white');
	    	} else {
	    		$('.navbar').removeClass('navbar--white');
	    	};

	    	if (nextIndex === 2) {
	    		scrollArrow.inverse();
	    		explodeTimer = setTimeout(() => {
	    			$('.explode__inner').addClass('explode__inner--active');

	    			explodeTimerStepTwo = setTimeout(() => {
	    				$('.explode__inner').addClass('explode__inner--fully-active');

	    				startToggleGroups();
	    			}, 1000);
	    		}, 1000);
	    	} else {
	    		blurOnParticle()
	    		clearTimeout(explodeTimer);
	    		clearTimeout(explodeTimerStepTwo);
	    		$('.explode__inner').removeClass('explode__inner--active');
	    		$('.explode__inner').removeClass('explode__inner--fully-active');
	    	};

	    	if (nextIndex !== 7 && !isMobile()) {
				scrollArrow.handleTimer();
			};
		    
		    setActiveNav(nextIndex);
		    setParticleText(store.getState().particles[0]);
		}
	});

	scrollerButton.on('click', event => {
		const sections = $('.section');
		const target = $(event.target.dataset.target);
		const index = sections.index(target);

		$.fn.fullpage.moveTo(index + 1);
	});

	// Когда слайды выстроились, прямчем прелодер //
	$(document).ready(hidePreloader);
});