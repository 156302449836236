import $ from 'jquery';
import { isMobile } from '../../_scripts/helpers';

const promoSlider = $('.slider--promo').slick({
	dots: true,
	arrow: true
});

const priceSlider = $('.slider--pricelist .slider__inner').slick({
	dots: true,
	arrow: false,
	centerMode: true,
	centerPadding: '20%',
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: false,
	prevArrow: '.controls__arrow--prev',
	nextArrow: '.controls__arrow--next',
	mobileFirst: true,
	responsive: [
		{
			breakpoint: 767,
			settings: {
				centerMode: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				dots: false,
				arrow: true
			}
		}
	]
});

const advantagesSlider = $('.slider--advantages').slick({
	dots: false,
	arrow: false,
	centerMode: true,
	centerPadding: '80px',
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: false,
	mobileFirst: true,
	responsive: [
		{
			breakpoint: 544,
			settings: {
				centerMode: false,
				slidesToShow: 2,
				slidesToScroll: 1,
				dots: false,
				arrow: false
			}
		},
		{
			breakpoint: 992,
			settings: {
				centerMode: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				dots: false,
				arrow: false
			}
		}
	]
});

const removeSlide = (slider, id) => {
	slider.slick('slickRemove', id);
};

priceSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
	const node = $('.slider__background');

	node.css('transform', `translateX(-${100 / 6 * nextSlide}%) translateY(-120%)`);
});

if (!isMobile()) {
	removeSlide(promoSlider, 4)
};

export { promoSlider, priceSlider, advantagesSlider, removeSlide };